const privacyPageB2bData = {
  page_header: {
    heading: `Privacy Policy - Data Controller`,
    style: "jumbo",
    subheading: `WINR Data respects your privacy. We work directly with global organisations and collect Business to Business contact data only through this secure website. Our business is to provide database management and third-party data licencing services.`,
  },
  content: `
  <h2>Contact Us</h2>
  <p>We are WINR Data Pty Ltd, 100 Walker Street, North Sydney, NSW 2060 Australia, ABN 73 653 357 450.</p> 
  <p></p>Contact us at <a href="mailto:info@winrdata.com">info@winrdata.com</a>. This Policy was last reviewed and updated July 2022.</p>
  <p>For any privacy or compliance questions or requests contact our Global Privacy Officer <a href="mailto:privacy@winrdata.com">privacy@winrdata.com</a></p>
  <p>We are registered with the UK Supervisory Authority, Information Commissioners Office (ICO). Our registration number is: ZA600332. If you believe we are not processing your personal data in accordance with the law, you have the right to contact your country's Supervisory Authority and in the instance of the UK - <a href="https://ico.org.uk">https://ico.org.uk</a>. Our EU Representative is VeraSafe UK and they can be contacted <a href="https://verasafe.com/public-resources/contact-data-protection-representative">https://verasafe.com/public-resources/contact-data-protection-representative</a>.</p>

  <h2>Your Data</h2>

  <p>The only information we collect is the contact details you provide on our <strong><a href="/contact/">Contact</a></strong> page. By completing the form, you are consenting to WINR making contact for business purposes. We collect name, company, email address and telephone number. There is a free form section, we would ask that you do not enter any sensitive information here.</p>
  <p>We transfer your data from this site through Formspree.io which in turn posts the data to our Microsoft Office account, so we will respond to your email. We use Google Analytics and you are free to manage the use of cookies through our cookie management software. We use your information only for the purpose of business communication and do not share it with any third-party unless it is for the direct purpose of providing a service to you. We store your details only for the lifecycle of our business relationship and no longer than necessary.</p>
 
  <h2>Your Privacy</h2>

  <p>You have the right to:</p>
  <ol>
  <li>Ask us what information we hold about you and receive a copy of this data</li>
  <li>Ask us to delete or stop communication with you</li>
  <li>Ask us to transfer a copy of your details to another organisation</li>
  <li>We would always encourage you to let us know if any information we hold on you is incorrect or out of date</li>
  </ol>  

  <p>We will acknowledge and act upon your request without delay. If at any time we cannot oblige, due to legal or contractual reasons, we will explain this to you.</p>
  <p>For cookie consent management please refer to our separate <strong><a href="/cookie-policy/">cookie&nbsp;policy</a>.</p>
  `,
}

export { privacyPageB2bData }
