import React from "react"
import styled from "styled-components"
import device from "utils/media"

const StyledPageContentWrapper = styled.div`
  padding: 0 0 60px 0;

  ul,
  ol {
    margin-bottom: ${({ theme }) => theme.spacing.md};

    li {
      margin-bottom: ${({ theme }) => theme.spacing.sm};

      @media ${device.md} {
        margin-bottom: 20px;
      }
    }

    &.content-list > li:before {
      top: 8px;
    }
    &.content-list.client-list {
      font-weight: 800;
      span {
        display: flex;
        flex-direction: column;
        width: 100%;
      }
      a {
        word-break: break-all;
      }
    }
  }

  ul {
    padding-left: 0px;
  }

  ol {
    list-style: decimal;
    padding-left: 30px;
    li {
      padding-left: 16px;
    }
  }

  ul ol {
    margin: 20px 0 0 0;
  }

  a {
    position: relative;
    color: ${({ theme }) => theme.color.teal};
    @media ${device.md} {
      display: inline-block;

      &::after {
        content: "";
        position: absolute;
        bottom: 2px;
        left: 0;
        right: 0;
        width: 100%;
        height: 2px;
        background-color: ${({ theme }) => theme.color.teal};
        will-change: transform;
        transition: ${({ theme }) => theme.transition.default};
      }
    }

    &:hover {
      color: ${({ theme }) => theme.color.dark_purple};

      @media ${device.md} {
        &::after {
          background-color: ${({ theme }) => theme.color.dark_purple};
          transform: translateY(2px);
        }
      }
    }
  }

  blockquote {
    border: 2px dashed ${({ theme }) => theme.color.teal};
    border-radius: 8px;
    padding: 18px;
    em {
      font-weight: 400;
    }
    p:last-child {
      margin-bottom: 0;
    }
    margin-bottom: 30px;
  }

  .CookieDeclarationDialogText,
  .CookieDeclarationIntro,
  .CookieDeclaration > p:nth-child(3) {
    display: none !important;
  }
`

const PageContentWrapper = ({ children }) => {
  return <StyledPageContentWrapper>{children}</StyledPageContentWrapper>
}

export default PageContentWrapper
